import React from "react";
import MenuDesktop from "./menu-desktop";
import MenuMobile from "./menu-mobile";

function Header({ onMenuButtonClicked, isSidebarMenuVisible, scrollRatio }) {
  return (
    <>
      <div
        className={
          "sidebar-menu-body-overlay fixed top-0 bottom-0 left-0 right-0 bg-gray-900 z-40 " +
          (isSidebarMenuVisible ? "activated" : "hidden")
        }
        id="sidebar-menu-body-overlay"
        onClick={onMenuButtonClicked}
      ></div>
      <header>
        <MenuMobile
          isSidebarMenuVisible={isSidebarMenuVisible}
          onMenuButtonClicked={onMenuButtonClicked}
        />
        <MenuDesktop scrollRatio={scrollRatio} />
      </header>
    </>
  );
}

export default Header;
