import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

function MenuMobile({ onMenuButtonClicked, isSidebarMenuVisible }) {
  return (
    <nav className="sm:hidden">
      <div className="fixed z-30 top-0 right-0 left-0 menu-opacity shadow pt-5 pb-3">
        <div className="text-center">
          <div
            className="fixed z-50 top-0 my-5 mx-4"
            id="menu-button"
            onClick={onMenuButtonClicked}
          >
            <span className="icon-Hamburger text-xl cursor-pointer"></span>
          </div>
          <AnchorLink title="Home" to="/#home">
            <span className="icon-logo text-2xl"></span>
          </AnchorLink>
        </div>
      </div>

      <div
        className={
          "sidebar-menu fixed z-40 w-4/6 shadow menu-opacity h-screen " +
          (isSidebarMenuVisible ? "open" : "")
        }
        id="sidebar-menu"
      >
        <div className="p-3">
          <span className="icon-logo-portfolio2019 text-xl"></span>
        </div>
        <ul className="flex flex-col px-4" onClick={onMenuButtonClicked}>
          <li className="mb-4 font-bold">
            <AnchorLink title="Portfolio" to="/#portfolio">
              Portfolio
            </AnchorLink>
          </li>
          <li className="mb-4 font-bold">
            <AnchorLink title="About" to="/#about">
              About
            </AnchorLink>
          </li>
          <li className="mb-4 font-bold">
            <AnchorLink title="Contact" to="/#contact">
              Contact
            </AnchorLink>
          </li>
        </ul>
      </div>
    </nav>
  );
}

export default MenuMobile;
