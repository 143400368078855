import React from "react";
import { AnchorLink } from "gatsby-plugin-anchor-links";

function MenuDesktop({ scrollRatio }) {
  const backgroundAlpha = scrollRatio !== undefined ? scrollRatio : 1;
  return (
    <nav
      className="fixed z-50 top-0 right-0 left-0 shadow pt-4 pb-3 px-5 hidden sm:block text-sm"
      style={{
        backgroundColor: `rgba(255,254,253,${backgroundAlpha})`,
        boxShadow: `0 1px 3px 0 rgba(0,0,0,${backgroundAlpha *
          0.1}), 0 1px 2px 0 rgba(0,0,0,${backgroundAlpha * 0.06})`
      }}
    >
      <div className="max-w-6xl mx-auto">
        <div className="flex justify-between">
          <AnchorLink title="Home" to="/#home">
            <span className="icon-logo text-2xl"></span>
          </AnchorLink>
          <div>
            <ul className="flex tracking-wider uppercase font-bold">
              <li className="mr-4">
                <AnchorLink title="Portfolio" to="/#portfolio">
                  Portfolio
                </AnchorLink>
              </li>
              <li className="mr-4">
                <AnchorLink title="About" to="/#about">
                  About
                </AnchorLink>
              </li>
              <li>
                <AnchorLink title="Contact" to="/#contact">
                  Contact
                </AnchorLink>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </nav>
  );
}

export default MenuDesktop;
