import React from "react";

function Footer() {
  const currentYear = new Date().getFullYear();

  return (
    <>
      <hr className="border-t border-gray-400 bg-light border-0" />
      <footer className="max-w-6xl mx-auto mt-5 mb-5">
        <div className="padding">
          <p className="mb-0">
            <span className="icon-logo text-xl"></span>
          </p>
          <p className="mb-0 mt-1 text-sm">
            ©{currentYear} - All rights reserved
          </p>
          <p className="text-sm">
            Handmade by{" "}
            <a
              href="https://www.linkedin.com/in/fabiendemangeat/en"
              target="blank"
              title="LinkedIn Fabien Demangeat"
            >
              Fabien Demangeat
            </a>{" "}
            and myself
          </p>
        </div>
      </footer>
    </>
  );
}

export default Footer;
