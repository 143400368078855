import React, { useState } from "react";
import PropTypes from "prop-types";
import Header from "./header";
import Footer from "./footer";
import Body from "./body";

function Layout({ children, scrollRatio }) {
  const [isSidebarMenuVisible, setIsSidebarMenuVisible] = useState(false);

  return (
    <Body isSidebarMenuVisible={isSidebarMenuVisible}>
      <Header
        isSidebarMenuVisible={isSidebarMenuVisible}
        onMenuButtonClicked={() =>
          setIsSidebarMenuVisible(!isSidebarMenuVisible)
        }
        scrollRatio={scrollRatio}
      />
      {children}
      <Footer />
    </Body>
  );
}

Layout.propTypes = {
  children: PropTypes.node.isRequired
};

export default Layout;
